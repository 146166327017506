@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

body {
  margin: 0;
  padding: 0;
  background: #111;
  overflow: hidden;
  /*font-family: Robot, sans-serif;*/
}

.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

/* Estilos para estrellas estáticas */
.star-static {
  position: absolute;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
}

/* Estilos para estrellas que caen */
.star-fall {
  position: absolute;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
  animation: fall infinite;
}


/* Animación de caída */
@keyframes fall {
  0% {
    transform: translateY(-100%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

/* Animación de titilar */
@keyframes twinkling {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes fall-diagonal {
  0% {
    transform: translate(-50vw, -100%); /* Comienza en una posición fuera de la pantalla (arriba a la izquierda) */
    opacity: 0.8;
  }
  100% {
    transform: translate(50vw, 100vh); /* Termina en la parte inferior derecha */
    opacity: 0;
  }
}